@import url('https://use.typekit.net/hau7hhv.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

.masthead {
    min-height: calc(100vh);
    background-color: #FF4527 !important;
    background-repeat: repeat;

    color: #E4E3C2;
}

.OrganizadoX{
    background-color: #E4E3C2 !important;
    background-repeat: repeat;

    color: #E4E3C2;
}
.footer{
    background-color: #1C1A24 !important;
    background-repeat: repeat;

    color: #E4E3C2;
}

.Home {
    /*min-height: calc(100vh - 60px);
    background-color: #FF4527 !important;
    background-repeat: repeat;

    color: #E4E3C2;*/

    padding-top: 5rem;
}
.Home__Flare { /*reparar esta wea porque no esta fijo en medio por los px*/
    width: 70px;
}
.Home__Flare-Last {
    width: 70px;
    margin-bottom: -2.2rem;
}

.Home__PremiosPicture{
    width:90%;
}

.Home__PngTitulos{
    width: 100%;
}
.Home__LogoOutline{
    width: 70%;
}
.Home__tituloNegro {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #1C1A24;
    margin-bottom: -0.5rem;
    font-size:1.5rem;
    
}

.Home__tituloBlanco {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #E4E3C2;
    margin-bottom: 1rem;
    font-size:1.5rem;
} 

.Home__parrafoBlanco {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #E4E3C2;
    font-size:1.5rem;
    line-height : 1.8rem;
}

.Home__parrafoNegro {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #1C1A24;
    font-size:1.5rem;
    line-height : 1.8rem;
} 






.Home__cta {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #E4E3C2;
    margin-left: 3rem;
    margin-right: 3rem;
    font-size:1rem;
}

.Home__Detalles {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #E4E3C2;
    margin-left: 6rem;
    margin-right: 6rem;
}

.Home__FooterText{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #E4E3C2;
    font-size:0.8rem;
    /* margin-left: 6rem;
    margin-right: 6rem; */
}
.Home__FooterTextTitulo{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #E4E3C2;
    font-size:1rem;
    /* margin-left: 6rem;
    margin-right: 6rem; */
}

.Home__ModalNumberStep {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size:1.5rem;
    color: #E4E3C2;
    /* margin-left: 3rem;
    margin-right: 3rem; */
}

.Home__ModalTextStep {
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    color: #1C1A24;
    /* margin-left: 3rem;
    margin-right: 3rem; */
}
.Home__ModalText {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #1C1A24;
    text-align: justify;
    /* margin-left: 3rem;
    margin-right: 3rem; */
}
.btn-NumberSteps {
background-color: #003C8A;
border: none;
padding-top: 10px;
padding-bottom: 10px;
width: 100%;
cursor: default;
}
.btn-TextSteps {
background-color: transparent;
border: 0.125rem solid transparent;
border-color:#003C8A;
padding-top: 10px;
padding-bottom: 10px;
width: 100%;
cursor: default;
}

.btn-dark {
color: #E4E3C2;
background-color: #1C1A24;
border: none;
padding-top: 10px;
padding-bottom: 10px;
}

.btn-dark:active{
color: #E4E3C2;
background-color: #003C8A;
border: none;
}
.btn-dark:hover{
color: #E4E3C2;
background-color: #003C8A;
border: none;
}
.btn-dark:focus{
color: #E4E3C2;
background-color: #003C8A;
border: none;
}
    

.modal-content {
    background-color: #E4E3C2;
    /* background-color: #1abc9c; */
    border: none;
    border-radius: 0rem;
}


.blast-modal .blast-modal-title {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #1C1A24;
    margin-bottom: -0.5rem;
    font-size:1.5rem;
    line-height: 2rem;
}

.blast-modal .blast-modal-titleOrange {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #FF4527;
    margin-bottom: -0.5rem;
    font-size:1.5rem;
    line-height: 2rem;
}

.blast-modal .blast-modal-parrafo {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #1C1A24;
    font-size:1.5rem;
    
    line-height: 2rem;
  }



@media only screen and (max-width: 575px) {

.Home__tituloNegro {
font-size:1.2rem;

}
.Home__tituloBlanco {
    font-size:1.2rem;
    
}
.Home__parrafoBlanco {
    font-size:1.2rem;
    line-height: 1.5rem;
}
.Home__parrafoNegro {
    font-size:1.2rem;
    line-height: 1.5rem;
}
.blast-modal .blast-modal-title {
    font-size: 1rem;
    line-height: 1.1rem;
    }
.blast-modal .blast-modal-titleOrange {
    font-size: 1rem;
    line-height: 1.1rem;
    }
.Home__ModalTextStep {
    font-size: 0.9rem;
    }
.Home__ModalText {
    font-size: 0.9rem;
    }
}


.blast-modal .close {
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 1rem;
  font-size: 2rem;
  line-height: 3rem;
  color: #E4E3C2;
  opacity: 1;
}

/**/

.divider-custom {
/* margin: 1.25rem 0 1.5rem; */
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}
.divider-custom .divider-custom-line {
width: 100%;
/* max-width: 7rem; */
height: 0.8rem;
background-color: #1C1A24;
border: none;
}
.divider-custom .divider-custom-line:first-child {
margin-right: 1rem;
}
.divider-custom .divider-custom-line:last-child {
margin-left: 1rem;
}
.divider-custom .divider-custom-icon {
color: #FF4527 !important;
font-size: 4rem;
}

.divider-custom .divider-custom-line2 {
width: 100%;
/* max-width: 7rem; */
height: 0.8rem;
background-color: #FF4527;
border: none;
}
.divider-custom .divider-custom-line2:first-child {
margin-right: 1rem;
}
.divider-custom .divider-custom-line2:last-child {
margin-left: 1rem;
}

.divider-custom .divider-custom-icon3 {
color: #E4E3C2 !important;
font-size: 3rem;
}

.footer-custom-icon{
    color: #E4E3C2 !important;
font-size: 1.5rem;
}

.divider-custom .divider-custom-line3 {
width: 100%;
/* max-width: 7rem; */
height: 0.8rem;
background-color: #E4E3C2;
border: none;
}
.divider-custom .divider-custom-line3:first-child {
margin-right: 1rem;
}
.divider-custom .divider-custom-line3:last-child {
margin-left: 1rem;
}

.divider-custom.divider-light .divider-custom-line {
background-color: #FF4527;
}

.divider-custom.divider-light .divider-custom-line2 {
background-color: #FF4527;
}

.divider-custom.divider-light .divider-custom-icon {
color: #FF4527 !important;
}

a {
    color: #E4E3C2 !important;
  }
a.enlaces {
    color: #FF4527 !important;
}
.noclick{
pointer-events: none;
}