@import url('https://use.typekit.net/hau7hhv.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

.Navbar {
  background-color: #1C1A24;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.navbar-brand{
  margin-right: 1rem;
  margin-left: 1rem; 
}

.navbar-brand__logo {
  width: 4rem; 
}

.Navbar__profile {
  padding-top: 0.5rem;
  
}
.Navbar__Menu {
  padding-top: 0.7rem;
}
.Navbar__Menu-icon {
  font-size:1.7rem;
  color: #E4E3C2;
}
.Navbar__Menu-icon:hover {
  color: #FF4527;
}

.Navbar__profile-icon {
  font-size:1.5rem;
  color: #E4E3C2;
  margin-right: 1.2rem;
 }
 .Navbar__profile-icon:hover {
  color: #FF4527;
}
.Navbar__links-item{
  font-family: 'Montserrat', sans-serif;
  color: #E4E3C2;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.Navbar__links-item:hover {
  color: #FF4527;
  text-decoration: none;
}
.dropdown-menu.show{
  font-family: 'Montserrat', sans-serif;
  color: #1C1A24;
  transform: translate3d(-50px, 62px, 0px) !important;
  background-color: #E4E3C2;
}
.dropdown-item {
  color: #1C1A24;
}
.dropdown-iten-assets:hover{
  text-decoration: none !important;
}

@media only screen and (max-width: 991px) {

  .navbar-brand__logo {
  font-size:1.2rem;
  /* transform: translate3d(50vw, 0px, 0px) !important; */
  }.Navbar__profile {
    position: absolute;
  transform: translate3d(calc(100vw - 40vw), 0px, 1px) !important;
  }
}
@media only screen and (max-width: 767px) {

.Navbar__profile {
    position: absolute;
  transform: translate3d(calc(100vw - 40vw), 0px, 1px) !important;
  }
}
@media only screen and (max-width: 575px) {

  .Navbar__profile {
      position: absolute;
    transform: translate3d(calc(100vw - 30vw), 0px, 1px) !important;
    }
  }



