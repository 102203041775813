@import url('https://use.typekit.net/hau7hhv.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap');

.masthead__registro {
    min-height: calc(100vh);
    background-color: #FF4527 !important;
    color: #E4E3C2;
    /* height: 100vh;
    min-height: 500px; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.masthead__usuario {
  min-height: calc(100vh);
  background-color: #1C1A24 !important;
  color: #E4E3C2;
  /* height: 100vh; */
  /* min-height: 500px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.masthead__nosotros {
  min-height: calc(100vh);
  background-color: #1C1A24 !important;
  color: #E4E3C2;
  /* height: 100vh;
  min-height: 500px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-registro {
    background-color: #E4E3C2 !important;
    width: 100%;
    text-align: center !important;
}
.text-Titulo {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #1C1A24;
    margin-bottom: -0.5rem;
    font-size:1.5rem;
    line-height : 1.8rem;
    padding-left: 10px;
    padding-right: 10px;
}
.text-Registro-14 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #1C1A24 !important;
    margin-bottom: 0rem !important;
    line-height : 1.2rem; 
}
.text-Registro-12 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    color: #1C1A24 !important;
    margin-bottom: 0rem !important;
    line-height : 1rem;
    
}
.Home__ctaRegistrarse {
  font-family: termina, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #E4E3C2;
  margin-left: 2rem;
  margin-right: 2rem;
  font-size:1rem;
}
.btn-darkSeguridad {
  color: #E4E3C2;
  background-color: #1C1A24;
  border: none;
  padding-top: 5px;
  padding-bottom: 8px;
  }
  .btn-darkSeguridad:active{
    color: #E4E3C2;
    background-color: #FF4527;
    border: none;
    }
    .btn-darkSeguridad:hover{
    color: #E4E3C2;
    background-color: #FF4527;
    border: none;
    }
    .btn-darkSeguridad:focus{
    color: #E4E3C2;
    background-color: #FF4527;
    border: none;
    }
.Home__AjustesSeguridad {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #E4E3C2;
  font-size: 0.8rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.IconSend {
  color: #1C1A24 !important;
  font-size: 4rem;
}

.selectItem{
  color: #1C1A24;
  background-color: #E4E3C2;
  border-radius: 0px;
  border-color: rgba(0, 0, 0, 0.5);
 
  /* box-shadow: 0 0 0 0.2rem rgba(26, 188, 156, 0.25); */
}
/* .form-control-file, .form-control-range */
.form-control-file{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  color: #1C1A24;
}
textarea.form-control {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #1C1A24 !important;
  /* margin-bottom: 0rem !important; */
  line-height : 1.2rem;
  background-color: #E4E3C2;
  border-radius: 0px;
  border-color: rgba(0, 0, 0, 0.5);

}





/* .text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
} */




.form-group {
  margin-bottom: 0rem;
}

.form-text {
  display: block;
  margin-top: 0rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 1rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #1C1A24;
}

.form-check-label {
  margin-bottom: 0;
}
/* 
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
} */
.form-control:focus {
  color: #1C1A24;
  background-color: #E4E3C2;
  border-color: #1C1A24;
  outline: 0;
  /* box-shadow: 0 0 0 0.2rem rgba(26, 188, 156, 0.25); */
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  border: 0px solid #1C1A24;
  -webkit-text-fill-color: #1C1A24;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.floating-label-form-group {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);;
  
}
.floating-label-form-group input,
.floating-label-form-group textarea {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  /* DESCOMENTAR PARA SEPARAR LINEA Y TEXTO
  margin-bottom: -1rem; */
  color: #1C1A24;
  position: relative;
  z-index: 1;
  padding-right: 0;
  padding-left: 0;
  resize: none;
  border: none;
  border-radius: 0;
  background: none;
  box-shadow: none !important;
}
/* texto que avisa q pex */
.floating-label-form-group label {
  font-size: 0.8rem;
  line-height: 1rem;
  position: relative;
  z-index: 0;
  top: 2em;
  display: block;
  margin: 0;
  transition: top 0.4s ease, opacity 0.4s ease;
  opacity: 0;
}
.floating-label-form-group:not(:first-child) {
  padding-left: 14px;
  border-left: 1px solid #E4E3C2;
}

.floating-label-form-group-with-value label {
  top: 0;
  opacity: 1;
}

.floating-label-form-group-with-focus label {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #1C1A24;
  
  
  /* margin-bottom: 0.5rem; */
}

form .row:first-child .floating-label-form-group {
  border-top: 1px solid #E4E3C2;
}




.Nosotros__Titulo {
  padding: 40px;
  background-color: #E4E3C2;
}
.Cover__Picture {
height: 65vh;
min-height: 350px;
background: no-repeat;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;
}
.Perfil__Picture{
  border-radius: 100%;
  border-color: #E4E3C2;
  border: 3px;
  /* position: absolute; */
  z-index: 1;

}
.Perfil_NameText{
  font-family: termina, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #E4E3C2;
  margin-bottom: -0.5rem;
  font-size:1.5rem;
  line-height : 1.8rem;
  padding-left: 10px;
  padding-right: 10px;
}
.Perfil_Participa{
  font-family: termina, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #E4E3C2;
  font-size:1.2rem;
  margin-bottom: -0.5rem;

}
.Perfil_ParticipaSB{
  font-family: termina, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #FF4527;
  font-size:1.2rem;
  margin-bottom: -0.5rem;
}
.Perfil__BioText{
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #E4E3C2;
  font-size:1rem;
}
.Seleccion_SB_Na{
  font-family: termina, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #FF4527;
  font-size: 1.5rem;
  line-height: 1.7rem;
  margin-bottom: 0.5rem;
}
.Seleccion__Detalles {
    font-family: termina, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #E4E3C2;
    margin-left: 4rem;
    margin-right: 4rem;
    font-size:1rem;
}


@media only screen and (max-width: 575px) {

  .text-Titulo {
  font-size:1.2rem;
  line-height: 1.5rem;
  
  }
  .Home__ctaRegistrarse {
      font-size:1rem;
  }
  .Home__parrafoNegro {
      font-size:1.2rem;
      line-height: 1.5rem;
  }.Perfil__BioText{
    font-size:0.8rem;
  }.Perfil_NameText{
    font-size:1.2rem;
  }.Seleccion_SB_Na{
    font-size:1.2rem;
    line-height: 1.4rem;
  }.text-Registro-14 {
    font-size: 0.9rem;
    line-height : 1.1rem; 
}
.text-Registro-12 {
    font-size: 0.7rem;
    line-height : 0.9rem;
    
}
  }

a {
  color: #E4E3C2 !important;
}
a.enlaces {
  color: #FF4527 !important;
}
.noclick{
pointer-events: none;
}